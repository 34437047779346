import "bootstrap";
import "jquery.backstretch";
import "@fontsource/rubik/500.css";


// import "@fortawesome/fontawesome-svg-core";
// import "@fortawesome/free-regular-svg-icons";
// import "@fortawesome/free-solid-svg-icons";

$(document).ready(function () {
    var base_url = $('#app-data').data('base-url');
    $.backstretch([
        base_url + "backend/usertemplate/assets/img/backgrounds/11.jpg"
    ], {duration: 3000, fade: 750});
    $('.login-form input[type="text"], .login-form input[type="password"], .login-form textarea').on('focus', function () {
        $(this).removeClass('input-error');
    });
    $('.login-form').on('submit', function (e) {
        $(this).find('input[type="text"], input[type="password"], textarea').each(function () {
            if ($(this).val() == "") {
                e.preventDefault();
                $(this).addClass('input-error');
            } else {
                $(this).removeClass('input-error');
            }
        });
    });

    $("#btnTogglePassword").on("click", function() {
        $("#togglePasswordIcon").toggleClass('fa-eye-slash').toggleClass('fa-eye');
        if($("#togglePasswordIcon").hasClass('fa-eye-slash')) {
            $("#password").attr("type", "password");
        } else {
            $("#password").attr("type", "text");
        }
    })
});